<template>
  <v-container fluid class="liSelectieMatrix body-2 pa-0 bg-dark d-flex" :class="{ mobile: isMobileApp }">
    <!-- PROJECT OPTIONS -->
    <v-sheet class="body-3 mt-2 liSelectionCol bg-dark">
      <v-expansion-panels v-model="selectionPanels" multiple flat>
        <v-expansion-panel>
          <v-expansion-panel-header class="border-bottom">
            <div class="liToolbar body-4 d-flex">
              <template v-if="newProjectStore.type != ''">
                <v-sheet light class="liTypeImage text-center border-right body-4">
                  <v-img :src="type.icon" class="mx-auto" width="45px" />
                  <span class="liTypeLabel">{{ type.text }}</span>
                </v-sheet>
                <v-sheet light class="pl-2 my-auto" style="line-height: 1.3">
                  <template v-if="newProjectStore.external != null">
                    {{ appConfig.texts.products.project.externalTitle }} <b> {{ externalOption.name }} </b><br />
                  </template>
                  <template v-if="(newProjectStore.lpl != null && newProjectStore.external == '1') || newProjectStore.external == '2'">
                    {{ appConfig.texts.products.project.lplTitle }} <b> {{ lplOption.name }} </b><br />
                  </template>
                  <template v-if="newProjectStore.grid != null">
                    {{ appConfig.texts.products.project.gridTitle }} <b> {{ newProjectStore.grid }} </b>
                  </template>
                </v-sheet>
              </template>
              <!-- RELOAD -->
              <div class="liSavingMatrix border-left border-right" v-if="localLoading">
                <v-progress-circular indeterminate color="dark" class="ma-auto" />
              </div>
              <v-tooltip left v-else-if="!localLoading && newProjectStore.type != ''">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="ml-auto border-left border-right rounded-0 liReloadProject" v-bind="attrs" v-on="on" small plain @click.stop="resetProject">
                    <v-icon>{{ appConfig.texts.products.project.refresh.icon }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ appConfig.texts.products.project.refresh.tooltip }}</span>
              </v-tooltip>
              <!-- /. RELOAD -->
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <!-- PROJECT TYPE -->
            <div class="d-flex w-100 border-bottom pa-3">
              <label v-html="appConfig.texts.type.title" />
            </div>
            <div class="d-flex typeTiles">
              <template v-for="(btn, n) in appConfig.texts.type.buttons">
                <v-sheet :key="n" v-if="btn.status == 1" light class="typeTile text-center px-3 pb-2 w-100" :class="[newProjectStore.type === btn.type ? 'primary white--text active' : '', isMobileApp ? '' : '', btn.disabled ? '' : 'cursor-pointer white', btn.class]" @click.prevent="btn.disabled ? '' : setType(btn.type)">
                  <v-img :src="newProjectStore.type === btn.type ? btn.iconSelected : btn.icon" width="70px" class="mx-auto" />
                  {{ btn.text }}
                </v-sheet>
              </template>
            </div>
            <!-- PROJECT EXTERNAL -->
            <template v-if="newProjectStore.type != ''">
              <div class="d-flex w-100 border-bottom px-3 py-2 mt-3">
                <label v-html="appConfig.texts.projects.newProject.external" />
                <liInfoModalBtn infoIdentifier="external" styleClasses="ml-auto mt-0 mr-0" />
              </div>
              <v-radio-group dense v-model="newProjectStore.external" row class="ma-0 liRadioAsButtons">
                <template v-for="(option, index) in appConfig.texts.projects.newProject.externalOptions">
                  <v-radio :key="index" :label="option.name" :value="option.value" class="mr-10 my-3 liRadioButton" @change="setExternal(option)" />
                </template>
              </v-radio-group>
            </template>
            <!-- PROJECT LPL -->
            <template v-if="newProjectStore.type != '' && newProjectStore.external == '1'">
              <div class="d-flex w-100 border-bottom px-3 py-2">
                <label v-html="appConfig.texts.projects.newProject.lplSelectionMatrix" />
                <liInfoModalBtn infoIdentifier="LPL" styleClasses="ml-auto mt-0 mr-0" />
              </div>
              <v-radio-group dense v-model="newProjectStore.lpl" row class="ma-0 liRadioAsButtons">
                <template v-for="(option, index) in appConfig.texts.projects.newProject.lplOptions">
                  <v-radio :key="index" :label="option.name" :value="option.value" class="mr-5 my-3 liRadioButton" @change="setLpl(option)" />
                </template>
              </v-radio-group>
            </template>
            <!-- PROJECT GRID -->
            <template v-if="showGrid">
              <div class="d-flex w-100 border-bottom px-3 py-2">
                <label v-html="appConfig.texts.projects.newProject.gridSelectionMatrix" />
                <liInfoModalBtn infoIdentifier="grid" styleClasses="ml-auto mt-0 mr-0" />
              </div>
              <v-radio-group dense v-model="newProjectStore.grid" row class="ma-0 liRadioAsButtons">
                <template v-for="(option, index) in appConfig.texts.projects.newProject.gridOptions">
                  <v-radio :key="index" :label="option.name" :value="option.value" class="mr-10 my-3 liRadioButton" @change="setGrid(option)" />
                </template>
              </v-radio-group>
            </template>
            <template v-if="newProjectStore.type != '' && newProjectStore.external != null && newProjectStore.grid != null">
              <!-- ITEM TYPE -->
              <div class="d-flex w-100 border-bottom px-3 py-2">
                <label v-html="appConfig.texts.itemTypes.title" />
              </div>
              <div class="d-flex typeTiles justify-space-between">
                <template v-for="(btn, n) in appConfig.texts.itemTypes.buttons">
                  <v-sheet :key="n" v-if="btn.status == 1" light class="typeTile text-center px-3 pb-2 w-100" :class="[newProjectStore.itemType === btn.type ? 'primary white--text active' : '', isMobileApp ? '' : '', btn.disabled ? '' : 'cursor-pointer white', btn.class]" @click.prevent="btn.disabled ? '' : selectItemType(btn.type)">
                    <v-img :src="newProjectStore.itemType === btn.type ? btn.iconSelected : btn.icon" width="70px" class="mx-auto" />
                    {{ btn.text }}
                  </v-sheet>
                </template>
              </div>
            </template>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel v-if="newProjectStore.itemType != null">
          <v-expansion-panel-header class="border-bottom">
            <div class="liToolbar body-4 d-flex">
              <v-sheet light class="liTypeImage text-center border-right body-4">
                <v-img :src="itemType.icon" class="mx-auto mt-1" width="50px" />
              </v-sheet>
              <v-sheet light class="pl-2 my-auto font-weight-bold" style="line-height: 1.3">
                {{ itemType.text }}
              </v-sheet>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <!-- √√√ MAIN DIVIDER -->
            <template v-if="newProjectStore.itemType == 9000">
              <div class="white mt-3">
                <!-- AMPERE -->
                <div class="w-100 pt-3 px-3">
                  <div class="d-flex w-100">
                    <label>{{ appConfig.texts.project.form.amp.label }} </label>
                    <liInfoModalBtn infoIdentifier="ampereMD" styleClasses="ml-auto mr-0" />
                  </div>
                  <v-autocomplete clearable dense :placeholder="appConfig.texts.project.form.amp.placeholder" :items="appConfig.texts.project.form.amp.options[`all`]" v-model="newProjectStore.ampere" item-value="value" item-text="name" class="pt-1" @input="checkMainDivider(newProjectStore)" @click:clear="checkMainDivider(newProjectStore)" />
                </div>
                <!-- KORTSLUITVASHEID -->
                <div class="white w-100 px-3" :class="{ liDisabled: shortCircuitResistanceOptions == null }">
                  <div class="d-flex w-100">
                    <label v-html="appConfig.texts.project.form.shortCircuitResistance.label" />
                    <liInfoModalBtn infoIdentifier="shortcircuit" styleClasses="ml-auto mr-0" />
                  </div>
                  <v-select clearable dense :disabled="shortCircuitResistanceOptions == null" :placeholder="appConfig.texts.project.form.shortCircuitResistance.placeholder" :items="shortCircuitResistanceOptions != null ? shortCircuitResistanceOptions : []" v-model="newProjectStore.shortCircuitResistance" item-value="value" item-text="name" class="pt-1" @input="checkMainDivider(newProjectStore)" @click:clear="checkMainDivider(newProjectStore)" />
                </div>
                <!-- INTEGREATE FUSE -->
                <div class="w-100 px-3" v-if="rule_NeedIntegratedFuse(newProjectStore)" :class="{ liDisabled: shortCircuitResistanceOptions == null }">
                  <div class="d-flex w-100">
                    <label>{{ appConfig.texts.project.form.preAmp.label }}</label>
                    <liInfoModalBtn infoIdentifier="integratedFuseMD" styleClasses="ml-auto mr-0" />
                  </div>
                  <v-select clearable dense :disabled="shortCircuitResistanceOptions == null" :placeholder="appConfig.texts.project.form.preAmp.placeholder" :items="appConfig.texts.project.form.preAmp.options" v-model="newProjectStore.preAmp" item-value="value" item-text="name" class="pt-1" @input="checkMainDivider(newProjectStore)" @click:clear="checkMainDivider(newProjectStore)" />
                </div>
              </div>
            </template>

            <!-- ### √ SUB DIVIDER -->
            <template v-if="newProjectStore.itemType == 1">
              <!-- LOCATION -->
              <template v-if="rule_NeedLocation()">
                <div class="d-flex w-100 border-bottom px-3 py-2">
                  <label v-html="appConfig.texts.project.form.external.labelSd" />
                  <liInfoModalBtn infoIdentifier="location" styleClasses="ml-auto" />
                </div>
                <v-radio-group dense v-model="newProjectStore.location" row class="liRadioAsButtons" @change="checkDivider(newProjectStore)">
                  <template v-for="(option, index) in appConfig.texts.project.form.external.options">
                    <v-radio :key="index" :label="option.name" :value="option.value" class="mr-10 liRadioButton" />
                  </template>
                </v-radio-group>
              </template>

              <!-- CABLE LENGTH -->
              <div class="d-flex w-100 border-bottom px-3 py-2">
                <label v-html="appConfig.texts.project.form.cableLength.labelMatrixSd" />
                <liInfoModalBtn infoIdentifier="cableLength" styleClasses="ml-auto" />
              </div>
              <v-radio-group dense v-model="newProjectStore.cableLength" row class="liRadioAsButtons" @change="setCableLength(newProjectStore)">
                <template v-for="(option, index) in appConfig.texts.project.form.cableLength.options">
                  <v-radio :key="index" :label="option.name" :value="option.value" class="mr-10 liRadioButton" />
                </template>
              </v-radio-group>

              <!-- LPL ZONE -->
              <template v-if="rule_NeedLpZone(newProjectStore)">
                <div class="d-flex w-100 border-bottom px-3 py-2">
                  <label v-html="appConfig.texts.project.form.lplZone.labelSd" />
                  <liInfoModalBtn infoIdentifier="LPL" styleClasses="ml-auto" />
                </div>
                <v-radio-group dense v-model="newProjectStore.lplZone" row class="liRadioAsButtons" @change="setLpZone(newProjectStore)">
                  <template v-for="(option, index) in appConfig.texts.project.form.lplZone.options">
                    <v-radio :key="index" :label="option.name" :value="option.value" class="mr-10 liRadioButton" />
                  </template>
                </v-radio-group>
              </template>

              <!-- NO PRODUCT REQUIRED CABLE LENGTH < 10 -->
              <div v-if="rule_ProductNotRequired(newProjectStore)" class="mt-3 w-100 d-flex px-3 pt-3 white">
                <v-alert dense text type="success" icon="mdi-shield-check" class="rounded-md"><span v-html="appConfig.texts.project.form.cableLength.explaination" /></v-alert>
              </div>

              <!-- PRODUCT -->
              <template v-if="newProjectStore.cableLength != '' && !rule_ProductNotRequired(newProjectStore)">
                <div class="white mt-3">
                  <!-- AMPERE -->
                  <div v-if="ampereOptionsSd != null" class="w-100 px-3" :class="{ liDisabled: ampereOptionsSd == null }">
                    <div class="d-flex w-100">
                      <label v-html="appConfig.texts.project.form.amp.label" />
                      <liInfoModalBtn infoIdentifier="ampereSD" styleClasses="ml-auto" />
                    </div>
                    <v-autocomplete dense clearable :placeholder="appConfig.texts.project.form.amp.placeholder" :items="ampereOptionsSd" v-model="newProjectStore.ampere" item-value="value" item-text="name" @input="checkDivider(newProjectStore)" @click:clear="checkDivider(newProjectStore)" />
                  </div>
                  <!-- KORTSLUITVASHEID -->
                  <div class="w-100 px-3" :class="{ liDisabled: shortCircuitResistanceOptions == null }">
                    <div class="d-flex w-100">
                      <label v-html="appConfig.texts.project.form.shortCircuitResistance.label" />
                      <liInfoModalBtn infoIdentifier="shortcircuit" styleClasses="ml-auto" />
                    </div>
                    <v-autocomplete dense clearable :disabled="shortCircuitResistanceOptions == null" :placeholder="appConfig.texts.project.form.shortCircuitResistance.placeholder" :items="shortCircuitResistanceOptions != null ? shortCircuitResistanceOptions : []" v-model="newProjectStore.shortCircuitResistance" item-value="value" item-text="name" @input="checkDivider(newProjectStore)" @click:clear="checkDivider(newProjectStore)" />
                  </div>
                  <!-- PHASE -->
                  <template v-if="rule_NeedPhaseSd(newProjectStore)">
                    <div class="w-100 px-3" :class="{ liDisabled: shortCircuitResistanceOptions == null }">
                      <div class="d-flex w-100">
                        <label v-html="appConfig.texts.project.form.phase.label" />
                        <liInfoModalBtn infoIdentifier="phase" styleClasses="ml-auto" />
                      </div>
                      <v-select dense clearable :disabled="shortCircuitResistanceOptions == null" :placeholder="appConfig.texts.project.form.phase.placeholder" :items="appConfig.texts.project.form.phase.options" v-model="newProjectStore.phase" item-value="value" item-text="name" @input="checkDivider(newProjectStore)" @click:clear="checkDivider(newProjectStore)" />
                    </div>
                  </template>
                  <!-- INTEGREATED FUSE -->
                  <div class="w-100 px-3" v-if="rule_NeedIntegratedFuse(newProjectStore)" :class="{ liDisabled: shortCircuitResistanceOptions == null }">
                    <div class="d-flex w-100">
                      <label v-html="appConfig.texts.project.form.preAmp.label" />
                      <liInfoModalBtn infoIdentifier="integratedFuseSD" styleClasses="ml-auto" />
                    </div>
                    <v-select dense clearable :disabled="shortCircuitResistanceOptions == null" :placeholder="appConfig.texts.project.form.preAmp.placeholder" :items="appConfig.texts.project.form.preAmp.options" v-model="newProjectStore.preAmp" item-value="value" item-text="name" @input="checkDivider(newProjectStore)" @click:clear="checkDivider(newProjectStore)" />
                  </div>
                </div>
              </template>
            </template>

            <!-- ### √ CRITICAL END GROUP -->
            <template v-if="newProjectStore.itemType == 2">
              <!-- LOCATION -->
              <template v-if="rule_NeedLocation()">
                <div class="d-flex w-100 border-bottom px-3 py-2">
                  <label v-html="appConfig.texts.project.form.external.labelCe" />
                  <liInfoModalBtn infoIdentifier="location" styleClasses="ml-auto" />
                </div>
                <v-radio-group dense v-model="newProjectStore.location" row class="liRadioAsButtons" @change="checkDivider(newProjectStore)">
                  <template v-for="(option, index) in appConfig.texts.project.form.external.options">
                    <v-radio :key="index" :label="option.name" :value="option.value" class="mr-10 liRadioButton" />
                  </template>
                </v-radio-group>
              </template>

              <!-- CABLE LENGTH -->
              <div class="d-flex w-100 border-bottom px-3 py-2">
                <label v-html="appConfig.texts.project.form.cableLength.labelMatrixCe" />
                <liInfoModalBtn infoIdentifier="cableLength" styleClasses="ml-auto" />
              </div>
              <v-radio-group dense v-model="newProjectStore.cableLength" row class="liRadioAsButtons" @change="setCableLength(newProjectStore)">
                <template v-for="(option, index) in appConfig.texts.project.form.cableLength.options">
                  <v-radio :key="index" :label="option.name" :value="option.value" class="mr-10 liRadioButton" />
                </template>
              </v-radio-group>

              <!-- LPL ZONE -->
              <template v-if="rule_NeedLpZone(newProjectStore)">
                <div class="d-flex w-100 border-bottom px-3 py-2">
                  <label v-html="appConfig.texts.project.form.lplZone.labelCe" />
                  <liInfoModalBtn infoIdentifier="LPL" styleClasses="ml-auto" />
                </div>
                <v-radio-group dense v-model="newProjectStore.lplZone" row class="liRadioAsButtons" @change="setLpZone(newProjectStore)">
                  <template v-for="(option, index) in appConfig.texts.project.form.lplZone.options">
                    <v-radio :key="index" :label="option.name" :value="option.value" class="mr-10 liRadioButton" />
                  </template>
                </v-radio-group>
              </template>

              <!-- NO PRODUCT REQUIRED CABLE LENGTH < 10 -->
              <div v-if="rule_ProductNotRequired(newProjectStore)" class="mt-3 w-100 d-flex px-3 pt-3 white">
                <v-alert dense text type="success" icon="mdi-shield-check" class="rounded-md"><span v-html="appConfig.texts.project.form.cableLength.explaination" /></v-alert>
              </div>

              <!-- PRODUCT -->
              <template v-if="newProjectStore.cableLength != '' && !rule_ProductNotRequired(newProjectStore)">
                <div class="white mt-3">
                  <!-- AMPERE -->
                  <div v-if="ampereOptionsCe != null" class="w-100 px-3" :class="{ liDisabled: ampereOptionsCe == null }">
                    <div class="d-flex w-100">
                      <label>{{ appConfig.texts.project.form.amp.label }} </label>
                      <liInfoModalBtn infoIdentifier="ampereSD" styleClasses="ml-auto" />
                    </div>
                    <v-autocomplete clearable dense :placeholder="appConfig.texts.project.form.amp.placeholder" :items="ampereOptionsCe" v-model="newProjectStore.ampere" item-value="value" item-text="name" @input="checkDivider(newProjectStore)" @click:clear="checkDivider(newProjectStore)" />
                  </div>
                  <!-- KORTSLUITVASHEID -->
                  <div class="w-100 px-3" :class="{ liDisabled: shortCircuitResistanceOptions == null }">
                    <div class="d-flex w-100">
                      <label v-html="appConfig.texts.project.form.shortCircuitResistance.label" />
                      <liInfoModalBtn infoIdentifier="shortcircuit" styleClasses="ml-auto" />
                    </div>
                    <v-autocomplete clearable dense :disabled="shortCircuitResistanceOptions == null" :placeholder="appConfig.texts.project.form.shortCircuitResistance.placeholder" :items="shortCircuitResistanceOptions != null ? shortCircuitResistanceOptions : []" v-model="newProjectStore.shortCircuitResistance" item-value="value" item-text="name" @input="checkDivider(newProjectStore)" @click:clear="checkDivider(newProjectStore)" />
                  </div>
                  <!-- PHASE -->
                  <template v-if="rule_NeedPhaseSd(newProjectStore)">
                    <div class="w-100 px-3" :class="{ liDisabled: shortCircuitResistanceOptions == null }">
                      <div class="d-flex w-100">
                        <label v-html="appConfig.texts.project.form.phase.label" />
                        <liInfoModalBtn infoIdentifier="phase" styleClasses="ml-auto" />
                      </div>
                      <v-select clearable dense required :disabled="shortCircuitResistanceOptions == null" :placeholder="appConfig.texts.project.form.phase.placeholder" :items="appConfig.texts.project.form.phase.options" v-model="newProjectStore.phase" item-value="value" item-text="name" @input="checkDivider(newProjectStore)" @click:clear="checkDivider(newProjectStore)" />
                    </div>
                  </template>
                  <!-- INTEGREATE FUSE -->
                  <div class="w-100 px-3" v-if="rule_NeedIntegratedFuse(newProjectStore)" :class="{ liDisabled: shortCircuitResistanceOptions == null }">
                    <div class="d-flex w-100">
                      <label v-html="appConfig.texts.project.form.preAmp.label" />
                      <liInfoModalBtn infoIdentifier="integratedFuseSD" styleClasses="ml-auto" />
                    </div>
                    <v-select clearable dense :disabled="shortCircuitResistanceOptions == null" :placeholder="appConfig.texts.project.form.preAmp.placeholder" :items="appConfig.texts.project.form.preAmp.options" v-model="newProjectStore.preAmp" item-value="value" item-text="name" @input="checkDivider(newProjectStore)" @click:clear="checkDivider(newProjectStore)" />
                  </div>
                </div>
              </template>
            </template>

            <!-- ### √ PRODUCT -->
            <div class="py-3 white mx-auto liProductSheet" v-if="newProjectStore.product != null && product(newProjectStore.product) != undefined">
              <div class="d-flex w-100 border-bottom px-3 py-2">
                <label class="font-weight-bold" v-html="appConfig.texts.project.form.selectProduct.label" />
                <v-tooltip left v-if="product(newProjectStore.product).url != ''">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on" x-small :href="product(newProjectStore.product).url" target="_blank" class="ml-auto"> <v-icon>mdi-information-outline</v-icon></v-btn>
                    </template>
                    <span>{{ appConfig.texts.project.moreInfoText }}</span>
                  </v-tooltip>
              </div>
              <div class="d-flex w-100 mt-3 px-3 ">
                <v-img :src="`${apiPublicUrl}${product(newProjectStore.product).thumbnailpath}`" class="mx-auto pa-1" width="100%" max-width="200px" max-height="200px" aspect-ratio="1" />
                <div class="w-100 ml-3 my-auto">
                  <v-chip v-if="newProjectStore.itemType != 9000 && newProjectStore.location == 1" color="blue" style="height: 18px" class="px-2 mr-1 body-4 white--text text-center"><b> 2x </b></v-chip>
                  <b> {{ product(newProjectStore.product).name }} </b>
                  <br />
                  {{ product(newProjectStore.product).article_number }}
                  <!-- PRODUCT OUTSIDE LPZ0 - EXTRA PRODUCT NEEDED-->
                  <div v-if="newProjectStore.itemType != 9000 && newProjectStore.location == 1" class="w-100 d-flex white mt-3">
                    <v-alert dense type="info" class="rounded-md w-100">
                      <div v-if="newProjectStore.itemType == 1" class="w-100" v-html="appConfig.texts.project.form.productAdvice.outsideDubbleProductSD" />
                      <div v-if="newProjectStore.itemType == 2" class="w-100" v-html="appConfig.texts.project.form.productAdvice.outsideDubbleProductCE" />
                    </v-alert>
                  </div>
                  <!-- PRODUCT NOTE -->
                  <div v-if="'productNote' in newProjectStore && newProjectStore.productNote != null && newProjectStore.productNote != ''" class="w-100 d-flex white mt-3">
                    <v-alert dense text type="warning" icon="mdi-flash-triangle" class="rounded-md w-100"><span v-html="newProjectStore.productNote" /></v-alert>
                  </div>
                </div>
              </div>
            </div>
            <!-- NO PRODUCT FOUND -->
            <div class="px-3 py-3 white mx-auto liProductSheet" v-if="'noProductReason' in newProjectStore && newProjectStore.noProductReason != ''">
              <v-alert dense text type="error" icon="mdi-flash-triangle" class="rounded-md w-100"><span v-html="newProjectStore.noProductReason" /></v-alert>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-sheet>
    <!--
    <pre>
      {{ newProjectStore }}
    </pre>
    -->
  </v-container>
</template>
<script>
import _ from "lodash";
const API_URL = process.env.VUE_APP_API_URL;
export default {
  name: "SelectieMatrix",
  title: "SelectieMatrix",
  data() {
    return {
      selectionPanels: [0],
      localLoading: false,
      defaultRules: [(v) => !!v || this.appConfig.texts.projects.required],
      apiPublicUrl: API_URL,
      ampereOptions: null,
      ampereOptionsSd: null,
      ampereOptionsCe: null,
      shortCircuitResistanceOptions: null,
    };
  },
  computed: {
    mdMatrix() {
      return this.$store.getters["liDehnProject/getCollection"](`matrix_md`);
    },
    sdMatrix() {
      return this.$store.getters["liDehnProject/getCollection"](`matrix_sd`);
    },
    ceMatrix() {
      return this.$store.getters["liDehnProject/getCollection"](`matrix_ce`);
    },
    matricesLoaded() {
      return this.mdMatrix != null && this.sdMatrix != null && this.ceMatrix != null && this.products != null;
    },
    setMainDividerAttributes() {
      return this.matricesLoaded && this.newProjectStore != null && this.newProjectStore.external != null && this.newProjectStore.itemType != null && (this.newProjectStore.external == "1" ? this.newProjectStore.lpl != null && this.newProjectStore.grid != null : this.newProjectStore.grid != null);
    },
    showProjectOptions() {
      return this.matricesLoaded;
    },
    type() {
      return this.appConfig.texts.type.buttons.find((i) => i.type == this.newProjectStore.type);
    },
    itemType() {
      return this.appConfig.texts.itemTypes.buttons.find((i) => i.type == this.newProjectStore.itemType);
    },
    externalOption() {
      return this.appConfig.texts.projects.newProject.externalOptions.find((i) => i.value == this.newProjectStore.external);
    },
    lplOption() {
      return this.appConfig.texts.projects.newProject.lplOptions.find((i) => i.value == this.newProjectStore.lpl);
    },
    showGrid() {
      if (this.newProjectStore.type != "" && this.newProjectStore.type != "00r" && this.newProjectStore.external != null && this.newProjectStore.external == "1" && this.newProjectStore.lpl == null) {
        return false;
      } else if (this.newProjectStore.type != "" && this.newProjectStore.external != null && this.newProjectStore.type != "00r") {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    matricesLoaded(val) {
      if (val) {
        if (this.newProjectStore.itemType != null) {
          if (this.newProjectStore.itemType == 9000) {
            setTimeout(() => {
              this.checkMainDivider(this.newProjectStore);
            }, 50);
            setTimeout(() => {
              this.selectionPanels = [1];
            }, 100);
          } else if (this.newProjectStore.itemType == 1 || this.newProjectStore.itemType == 2) {
            setTimeout(() => {
              this.checkDivider(this.newProjectStore);
            }, 50);
            setTimeout(() => {
              this.selectionPanels = [1];
            }, 100);
          }
        }
      }
    },
  },
  mounted() {
    // this.getProducts();
    this.setupDividerAmpereOptions();
    this.setupSdAmpereOptions();
    this.setupCeAmpereOptions();
    this.setupTitle();
    this.getMatrices();
  },
  methods: {
    resetProject() {
      this.$store.dispatch("liDehnProject/SET_NEW_PROJECT");
      setTimeout(() => {
        this.selectionPanels = [0];
      }, 100);
    },
    updateLocalStorageNew() {
      this.localLoading = true;
      setTimeout(() => {
        this.$store.dispatch("liDehnProject/UPDATE_LOCALSTORAGE_NEW");
        this.localLoading = false;
      }, 250);
    },
    // SET STUFF
    setType(option) {
      this.$store.dispatch("liDehnProject/SET_TYPE_NEW", option);
      // When option == 00r (woonhuis) also set grid
      if (option == "00r") {
        this.setGrid({ value: "TT" });
      } else {
        this.setGrid({ value: null });
      }
      this.setLpl({ value: null });
      this.setExternal({ value: null });
      this.updateLocalStorageNew();
    },
    setGrid(option) {
      this.$store.dispatch("liDehnProject/SET_GRID_NEW", option.value);
    },
    setExternal(option) {
      this.$store.dispatch("liDehnProject/SET_EXTERNAL_NEW", option.value);
      // When external option == nee set lpl to null
      if (option.value == 0) {
        this.setLpl({ value: null });
      }
      this.updateLocalStorageNew();
    },
    setLpl(option) {
      this.$store.dispatch("liDehnProject/SET_LPL_NEW", option.value);
      this.updateLocalStorageNew();
    },
    setLpZone(selectedItem) {
      this.clearProduct(selectedItem);
      this.checkDivider(selectedItem);
    },
    setCableLength(selectedItem) {
      if (selectedItem.cableLength == "<10") {
        this.clearProduct(selectedItem);
        this.checkDivider(selectedItem);
      } else {
        this.checkDivider(selectedItem);
      }
    },
    selectItemType(option) {
      if (this.newProjectStore.itemType != null) {
        this.clearProduct(this.newProjectStore);
        this.$set(this.newProjectStore, "cableLength", "");
        this.$set(this.newProjectStore, "location", "");
        this.$set(this.newProjectStore, "lplZone", "");
        this.$set(this.newProjectStore, "ampere", "");
        this.$set(this.newProjectStore, "shortCircuitResistance", "");
        this.$set(this.newProjectStore, "preAmp", "");
        this.$set(this.newProjectStore, "phase", "");
        setTimeout(() => {
          this.$store.dispatch("liDehnProject/SET_ITEMTYPE_NEW", option);
        }, 50);
        setTimeout(() => {
          this.selectionPanels = [1];
          this.updateLocalStorageNew();
          if (this.newProjectStore.itemType != null) {
            if (this.newProjectStore.itemType == 9000) {
              this.checkMainDivider(this.newProjectStore);
            } else {
              this.checkDivider(this.newProjectStore);
            }
          }
        }, 100);
      } else {
        this.$store.dispatch("liDehnProject/SET_ITEMTYPE_NEW", option);
        setTimeout(() => {
          this.selectionPanels = [1];
          this.updateLocalStorageNew();
          if (this.newProjectStore.itemType != null) {
            if (this.newProjectStore.itemType == 9000) {
              this.checkMainDivider(this.newProjectStore);
            } else {
              this.checkDivider(this.newProjectStore);
            }
          }
        }, 100);
      }
    },
    clearProduct(selectedItem) {
      this.$set(selectedItem, "product", "");
      this.$set(selectedItem, "productNote", "");
      this.$set(selectedItem, "productOptions", []);
      if ("productOptions" in selectedItem && selectedItem.productOptions != "") {
        this.$set(selectedItem, "productOptions", "");
      }
    },
    setupDividerAmpereOptions() {
      let ampOptions = this.appConfig.texts.project.form.amp.options[`all`];
      this.ampereOptions = ampOptions;
    },
    setupCeAmpereOptions() {
      let ampOptions = this.appConfig.texts.project.form.amp.options[`ceSpecific`];
      this.ampereOptionsCe = ampOptions;
    },
    setupSdAmpereOptions() {
      let ampOptions = this.appConfig.texts.project.form.amp.options[`sdSpecific`];
      this.ampereOptionsSd = ampOptions;
    },
    // COMPONENT FUNCTIONS
    setupTitle() {
      this.$title = this.appConfig.texts.products.title;
    },
    // RULES
    rule_NeedIntegratedFuse(selectedItem) {
      if (Number(selectedItem.external) == 1) {
        if (Number(selectedItem.lpl) == 1 || Number(selectedItem.lpl) == 2) {
          selectedItem.preAmp = Number(selectedItem.ampere) <= 250 ? "nee" : selectedItem.preAmp;
          return Number(selectedItem.ampere) <= 250 ? false : true;
        } else if (Number(selectedItem.lpl) == 3 || Number(selectedItem.lpl) == 4) {
          selectedItem.preAmp = Number(selectedItem.ampere) <= 160 ? "nee" : selectedItem.preAmp;
          return Number(selectedItem.ampere) <= 250 ? false : true;
        }
      } else {
        if (Number(selectedItem.ampere) <= 125 || Number(selectedItem.shortCircuitResistance) <= 50) {
          selectedItem.preAmp = Number(selectedItem.ampere) <= 160 ? "nee" : selectedItem.preAmp;
          return false;
        } else {
          return true;
        }
      }
    },
    rule_NeedLpZone(selectedItem) {
      return this.newProjectStore.external == 1 && selectedItem.location == 1 && selectedItem.cableLength == ">10" ? true : false;
    },
    rule_NeedLocation() {
      return this.newProjectStore.external == 1 ? true : false;
    },
    rule_ProductNotRequired(selectedItem) {
      return selectedItem.cableLength == "<10" ? true : false;
    },
    rule_NeedPhaseSd(selectedItem) {
      return Number(selectedItem.ampere) <= 63 || Number(selectedItem.ampere) == "" ? true : false;
    },
    rule_SetPhase(selectedItem) {
      return selectedItem.ampere > 40 ? true : false;
    },
    // EDIT ITEM FUNCTIONS
    getMatrices() {
      this.$store.dispatch("liDehnProject/GET_COLLECTION", {
        request: {
          object: "li_matrix_md",
          orderBy: "id ASC",
          where: "deleted_at IS NULL AND status = 1",
          select: "*",
        },
        collectionName: `matrix_md`,
      });
      this.$store.dispatch("liDehnProject/GET_COLLECTION", {
        request: {
          object: "li_matrix_sd",
          orderBy: "id ASC",
          where: "deleted_at IS NULL AND status = 1",
          select: "*",
        },
        collectionName: `matrix_sd`,
      });
      this.$store.dispatch("liDehnProject/GET_COLLECTION", {
        request: {
          object: "li_matrix_ce",
          orderBy: "id ASC",
          where: "deleted_at IS NULL AND status = 1",
          select: "*",
        },
        collectionName: `matrix_ce`,
      });
    },
    // CHECK FUNCTIONS
    checkMainDivider(selectedItem) {
      // console.log("checkMainDivider", selectedItem);
      this.$set(selectedItem, "productOptions", []);
      this.$set(selectedItem, "product", "");
      this.$set(selectedItem, "productNote", "");
      this.$set(selectedItem, "noProductReason", "");

      const ex = this.newProjectStore.external == 0 ? "ex-nee" : this.newProjectStore.external == 2 ? "ex-nb" : "ex-ja";
      const lpl = this.newProjectStore.lpl == 0 ? "lpl-NB" : this.newProjectStore.lpl == 1 ? "lpl-I" : this.newProjectStore.lpl == 2 ? "lpl-II" : this.newProjectStore.lpl == 3 ? "lpl-III" : "lpl-IV";

      //* SHORT CIRCUIT RESISTANCE OPTIONS *\\
      let shortCircuitResistanceOptions = [];
      if (selectedItem.ampere > 32 && selectedItem.ampere <= 800) {
        // console.log('Need to remove all below 33');
        this.appConfig.texts.project.form.shortCircuitResistance.options.forEach((option) => {
          if (option.value > 6 && option.value < 100) {
            shortCircuitResistanceOptions.push(option);
          }
        });
        if (selectedItem.shortCircuitResistance == 6 || selectedItem.shortCircuitResistance == 100) {
          this.$set(selectedItem, "shortCircuitResistance", "");
        }
      } else if (selectedItem.ampere >= 800) {
        this.appConfig.texts.project.form.shortCircuitResistance.options.forEach((option) => {
          if (option.value > 6) {
            shortCircuitResistanceOptions.push(option);
          }
        });
        if (selectedItem.shortCircuitResistance == 6) {
          this.$set(selectedItem, "shortCircuitResistance", "");
        }
      } else if (selectedItem.ampere <= 32) {
        this.appConfig.texts.project.form.shortCircuitResistance.options.forEach((option) => {
          if (option.value < 100) {
            shortCircuitResistanceOptions.push(option);
          }
        });
        if (selectedItem.shortCircuitResistance >= 100) {
          this.$set(selectedItem, "shortCircuitResistance", "");
        }
      }
      shortCircuitResistanceOptions = [...shortCircuitResistanceOptions, this.appConfig.texts.project.form.shortCircuitResistance.notKnown];
      this.shortCircuitResistanceOptions = shortCircuitResistanceOptions;
  console.log('checkMainDivider',selectedItem.preAmp)
      //* PRODUCT *\\
      if (!_.isEmpty(selectedItem.ampere) && !_.isEmpty(selectedItem.shortCircuitResistance) && !_.isEmpty(selectedItem.preAmp)) {
        let products = this.mdMatrix.filter((item) => {
          if (this.newProjectStore.external == 0 || this.newProjectStore.external == 2) {
            if (item[selectedItem.grid] != null && item[selectedItem.grid] != undefined && item[selectedItem.grid] != "" && item[ex] == 1 && item[`aw-${selectedItem.ampere}`] == 1 && item[`kv-${selectedItem.shortCircuitResistance}`] == 1 && item[`if-${selectedItem.preAmp}`]) {
              return item;
            }
          } else {
            if (item[selectedItem.grid] != null && item[selectedItem.grid] != undefined && item[selectedItem.grid] != "" && item[ex] == 1 && item[lpl] == 1 && item[`aw-${selectedItem.ampere}`] == 1 && item[`kv-${selectedItem.shortCircuitResistance}`] == 1 && item[`if-${selectedItem.preAmp}`]) {
              return item;
            }
          }
        });
        if (products != undefined && products.length > 0) {
          this.$set(selectedItem, "product", products[0][selectedItem.grid]);
          this.$set(selectedItem, "productNote", products[0].Opmerkingen);
          this.$set(selectedItem, "noProductReason", "");
          if (products.length > 1) {
            let productIdentifiers = [];
            products.forEach((product) => {
              productIdentifiers.push(product[selectedItem.grid]);
            });
            this.$set(selectedItem, "productOptions", productIdentifiers);
          }
        } else {
          this.$set(selectedItem, "productOptions", []);
          this.$set(selectedItem, "product", "");
          this.$set(selectedItem, "productNote", "");
          this.$set(selectedItem, "noProductReason", `${this.appConfig.texts.project.form.noProductReason} <br><br><span><b>Aansluitwaarde:</b> ${selectedItem.ampere} A<br><b>Kortsluitvastheid:</b> ${selectedItem.shortCircuitResistance} kArms<br><b>Voorzekering:</b> ${selectedItem.preAmp}</span> `);
        }
      }
      this.updateLocalStorageNew();
    },
    checkDivider(selectedItem) {
      const ex = selectedItem.external == 0 ? "ex-nee" : selectedItem.external == 2 ? "ex-nb" : "ex-ja";
      const _in = selectedItem.location == 0 ? "binnen" : "buiten";
      const lpl = selectedItem.lpl == 0 ? "lpl-NB" : selectedItem.lpl == 1 ? "lpl-I" : selectedItem.lpl == 2 ? "lpl-II" : selectedItem.lpl == 3 ? "lpl-III" : "lpl-IV";
      const lpz = selectedItem.lplZone == "a" ? "lpz0a" : selectedItem.lplZone == "b" ? "lpz0b" : "lpznb";

      this.$set(selectedItem, "product", "");
      this.$set(selectedItem, "productNote", "");
      this.$set(selectedItem, "noProductReason", "");
      this.$set(selectedItem, "productOptions", []);

      let shortCircuitResistanceOptions = [];
      if (selectedItem.type == 1) {
        //* SHORT CIRCUIT RESISTANCE RULES SUB DIVIDER *\\
        if (selectedItem.ampere > 32 && selectedItem.ampere <= 800) {
          this.appConfig.texts.project.form.shortCircuitResistance.options.forEach((option) => {
            if (option.value > 6 && option.value < 100) {
              shortCircuitResistanceOptions.push(option);
            }
          });
          if (selectedItem.shortCircuitResistance == 6 || selectedItem.shortCircuitResistance == 100) {
            this.$set(selectedItem, "shortCircuitResistance", "");
          }
        } else if (selectedItem.ampere >= 800) {
          this.appConfig.texts.project.form.shortCircuitResistance.options.forEach((option) => {
            if (option.value > 6) {
              shortCircuitResistanceOptions.push(option);
            }
          });
          if (selectedItem.shortCircuitResistance == 6) {
            this.$set(selectedItem, "shortCircuitResistance", "");
          }
        } else if (selectedItem.ampere <= 32) {
          this.appConfig.texts.project.form.shortCircuitResistance.options.forEach((option) => {
            if (option.value < 100) {
              shortCircuitResistanceOptions.push(option);
            }
          });
          if (selectedItem.shortCircuitResistance >= 100) {
            selectedItem.shortCircuitResistance = "";
          }
        }
        this.shortCircuitResistanceOptions = [...shortCircuitResistanceOptions, this.appConfig.texts.project.form.shortCircuitResistance.notKnown];
      } else {
        //* SHORT CIRCUIT RESISTANCE RULES CRITICAL END GROUP *\\
        if (selectedItem.ampere > 32 && selectedItem.ampere <= 800) {
          this.appConfig.texts.project.form.shortCircuitResistanceCe.options.forEach((option) => {
            if (option.value > 6 && option.value < 100) {
              shortCircuitResistanceOptions.push(option);
            }
          });
          if (selectedItem.shortCircuitResistance == 6 || selectedItem.shortCircuitResistance == 100) {
            this.$set(selectedItem, "shortCircuitResistance", "");
          }
        } else if (selectedItem.ampere >= 800) {
          this.appConfig.texts.project.form.shortCircuitResistanceCe.options.forEach((option) => {
            if (option.value > 6) {
              shortCircuitResistanceOptions.push(option);
            }
          });
          if (selectedItem.shortCircuitResistance == 6) {
            this.$set(selectedItem, "shortCircuitResistance", "");
          }
        } else if (selectedItem.ampere <= 32) {
          this.appConfig.texts.project.form.shortCircuitResistanceCe.options.forEach((option) => {
            if (option.value < 100) {
              shortCircuitResistanceOptions.push(option);
            }
          });
          if (selectedItem.shortCircuitResistance >= 100) {
            selectedItem.shortCircuitResistance = "";
          }
        }
        this.shortCircuitResistanceOptions = [...shortCircuitResistanceOptions, this.appConfig.texts.project.form.shortCircuitResistance.notKnown];
      }

      //* PHASE RULES *\\
      if (this.rule_SetPhase(selectedItem)) {
        this.$set(selectedItem, "phase", "3FN");
        console.log("rule_SetPhase has changed the phase to = ", selectedItem.phase);
      }
      let products = "";
      //* SELECT PRODUCT *\\
      if (this.rule_ProductNotRequired(selectedItem)) {
        // PRODUCT NOT REQUIRED
        console.log("Product not required");
        this.$set(selectedItem, "product", "");
        this.$set(selectedItem, "productNote", this.appConfig.texts.project.itemIsSecure);
        this.$set(selectedItem, "noProductReason", "");
        this.$set(selectedItem, "productOptions", []);
      } else if (!_.isEmpty(selectedItem.grid) && !_.isEmpty(selectedItem.ampere) && !_.isEmpty(selectedItem.shortCircuitResistance) && !_.isEmpty(selectedItem.preAmp) && !_.isEmpty(selectedItem.phase)) {
        // EXTERNAL LIGHTNING PROTECTION OR NOT
        if (this.newProjectStore.external == 0 || this.newProjectStore.external == 2) {
          // NO EXTERNAL PROTECTION
          // * FOR  SUBDIVIDER *\\
          if (selectedItem.itemType == 1) {
            products = this.sdMatrix.filter((item) => {
              if (_in == "binnen") {
                if (item[selectedItem.grid] != null && item[selectedItem.grid] != undefined && item[selectedItem.grid] != "" && item[`aw-${selectedItem.ampere}`] == 1 && item[`kv-${selectedItem.shortCircuitResistance}`] == 1 && item[selectedItem.phase] == 1 && item[`if-${selectedItem.preAmp}`] == 1 && item[ex] == 1 && item[_in] == 1) {
                  return item;
                }
              } else {
                if (item[selectedItem.grid] != null && item[selectedItem.grid] != undefined && item[selectedItem.grid] != "" && item[`aw-${selectedItem.ampere}`] == 1 && item[`kv-${selectedItem.shortCircuitResistance}`] == 1 && item[selectedItem.phase] == 1 && item[`if-${selectedItem.preAmp}`] == 1 && item[ex] == 1 && item[_in] == 1 && item[lpz] == 1) {
                  return item;
                }
              }
            });
          }
          // * FOR  CRITICAL END GROUPS *\\
          else if (selectedItem.itemType == 2) {
            products = this.ceMatrix.filter((item) => {
              if (_in == "binnen") {
                if (item[selectedItem.grid] != null && item[selectedItem.grid] != undefined && item[selectedItem.grid] != "" && item[`aw-${selectedItem.ampere}`] == 1 && item[`kv-${selectedItem.shortCircuitResistance}`] == 1 && item[selectedItem.phase] == 1 && item[`if-${selectedItem.preAmp}`] == 1 && item[ex] == 1 && item[_in] == 1) {
                  return item;
                }
              } else {
                if (item[selectedItem.grid] != null && item[selectedItem.grid] != undefined && item[selectedItem.grid] != "" && item[`aw-${selectedItem.ampere}`] == 1 && item[`kv-${selectedItem.shortCircuitResistance}`] == 1 && item[selectedItem.phase] == 1 && item[`if-${selectedItem.preAmp}`] == 1 && item[ex] == 1 && item[_in] == 1 && item[lpz] == 1) {
                  return item;
                }
              }
            });
          }
        } else {
          // EXTERNAL PROTECTION
          // * FOR  SUBDIVIDER *\\
          if (selectedItem.itemType == 1) {
            products = this.sdMatrix.filter((item) => {
              if (_in == "binnen") {
                if (item[selectedItem.grid] != null && item[selectedItem.grid] != undefined && item[selectedItem.grid] != "" && item[`aw-${selectedItem.ampere}`] == 1 && item[`kv-${selectedItem.shortCircuitResistance}`] == 1 && item[selectedItem.phase] == 1 && item[`if-${selectedItem.preAmp}`] == 1 && item[ex] == 1 && item[_in] == 1 && item[lpl] == 1) {
                  return item;
                }
              } else {
                if (item[selectedItem.grid] != null && item[selectedItem.grid] != undefined && item[selectedItem.grid] != "" && item[`aw-${selectedItem.ampere}`] == 1 && item[`kv-${selectedItem.shortCircuitResistance}`] == 1 && item[selectedItem.phase] == 1 && item[`if-${selectedItem.preAmp}`] == 1 && item[ex] == 1 && item[_in] == 1 && item[lpl] == 1 && item[lpz] == 1) {
                  return item;
                }
              }
            });
          }
          // * FOR  CRITICAL END GROUPS *\\
          else if (selectedItem.itemType == 2) {
            products = this.ceMatrix.filter((item) => {
              if (_in == "binnen") {
                if (item[selectedItem.grid] != null && item[selectedItem.grid] != undefined && item[selectedItem.grid] != "" && item[`aw-${selectedItem.ampere}`] == 1 && item[`kv-${selectedItem.shortCircuitResistance}`] == 1 && item[selectedItem.phase] == 1 && item[`if-${selectedItem.preAmp}`] == 1 && item[ex] == 1 && item[_in] == 1 && item[lpl] == 1) {
                  return item;
                }
              } else {
                if (item[selectedItem.grid] != null && item[selectedItem.grid] != undefined && item[selectedItem.grid] != "" && item[`aw-${selectedItem.ampere}`] == 1 && item[`kv-${selectedItem.shortCircuitResistance}`] == 1 && item[selectedItem.phase] == 1 && item[`if-${selectedItem.preAmp}`] == 1 && item[ex] == 1 && item[_in] == 1 && item[lpl] == 1 && item[lpz] == 1) {
                  return item;
                }
              }
            });
          }
        }
        if (products != undefined && products.length > 0) {
          if (products[0] != undefined && products[0][selectedItem.grid] != null) {
            this.$set(selectedItem, "product", products[0][selectedItem.grid]);
            this.$set(selectedItem, "productNote", products[0].Opmerkingen);
            this.$set(selectedItem, "noProductReason", "");
            if (products.length > 1) {
              let productIdentifiers = [];
              products.forEach((product) => {
                productIdentifiers.push(product[selectedItem.grid]);
              });
              this.$set(selectedItem, "productOptions", productIdentifiers);
            }
          } else {
            this.$set(selectedItem, "productOptions", []);
            this.$set(selectedItem, "product", "");
            this.$set(selectedItem, "productNote", "");
            this.$set(selectedItem, "noProductReason", `${this.appConfig.texts.project.form.foundButNoArticleNo} <b>${products[0].Name}</b> - <b>${selectedItem.grid}</b>`);
          }
        } else {
          this.$set(selectedItem, "productOptions", []);
          this.$set(selectedItem, "product", "");
          this.$set(selectedItem, "productNote", "");
          this.$set(selectedItem, "noProductReason", `${this.appConfig.texts.project.form.noProductReason} <br><br><span><b>Aansluitwaarde:</b> ${selectedItem.ampere} A<br><b>Kortsluitvastheid:</b> ${selectedItem.shortCircuitResistance} kArms<br><b>Fase:</b> ${selectedItem.phase}<br><b>Voorzekering:</b> ${selectedItem.preAmp}</span> `);
        }
        // console.log(products);
      } else {
        console.log("Not complete item");
        this.$set(selectedItem, "product", "");
        this.$set(selectedItem, "productNote", "");
        this.$set(selectedItem, "noProductReason", "");
        this.$set(selectedItem, "productOptions", []);
      }
      this.updateLocalStorageNew();
    },
  },
};
</script>
